import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from 'components/layout/layout';
import { useQuery } from 'react-query';
import { formatCategory } from 'helpers/integrationFormat';
import Loader from 'components/ui/loading';
import SideBar from 'components/sideBar/sideBar';
import Content from 'components/content/content';
import { GridBox } from 'pages/index';
import Banner from 'components/banner/banner';
import arrow from 'images/ui/arrow_black.svg';
import { media } from 'theme/breakpoints';
import { fetchIntegrationCategory } from 'api/api';
import { formatIntegrations } from 'hooks/useFormattedIntegrations';

export const ExtendGridBox = styled(GridBox)`
  padding-bottom: 0;
  grid-template-rows: auto auto;
  grid-template-areas:
    'breadcrumbs breadcrumbs'
    'sidebar content';
  ${media.tablet} {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'breadcrumbs'
      'sidebar'
      'content';
    grid-gap: 40px;
  }
  ${media.mobile} {
    grid-template-columns: 100vw;
  }
  & main {
    padding-top: 36px !important;
  }
  .breadcrumbs {
    grid-area: breadcrumbs;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    & img {
      margin: 0 16px;
    }
    & p {
      margin: 0;
      line-height: normal;
      & a {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Section = styled.section`
  padding-bottom: 0;
  padding-top: 0;
  display: grid;
  grid-template-columns: 356px auto;
`;

interface CategoryPageProps {
  pageContext: {
    slug: string;
  };
}

const CategoryPage: React.FC<CategoryPageProps> = ({ pageContext: { slug } }) => {
  const currentCategory = slug.split('/')[2];

  const { isLoading, data } = useQuery(`fetch-integrations`, () => fetchIntegrationCategory(''), {
    cacheTime: 1000 * 60 * 60 * 24,
    staleTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    window.parent.postMessage(window.location.pathname, `${process.env.GATSBY_APP_URL}`);
  }, []);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (typeof e.data === 'string' && e.data.length > 0) {
        const message = JSON.parse(e.data);
        sessionStorage.setItem('installedIntegrations', message.integrationsInstalled);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage, false);
  }, []);

  const formattedIntegration = data && formatIntegrations(data);

  const categoryTitle = currentCategory && formatCategory(currentCategory);

  const mostPopularCard =
    formattedIntegration &&
    formattedIntegration.filter(({ categories }) => {
      return categories?.find((item) => item === formatCategory(currentCategory));
    });

  return (
    <Layout
      canonical={slug}
      metaTitle={`${categoryTitle} | Integrations`}
      metaDescription={categoryTitle}
      // image={card.cover}
    >
      {isLoading || !formattedIntegration ? (
        <Loader fullScreen />
      ) : (
        <Section>
          <SideBar activeCategory={categoryTitle} integrations={formattedIntegration} />
          <ExtendGridBox>
            <Content
              activeCategory={categoryTitle}
              hideTitle
              isLoading={isLoading}
              data={formattedIntegration}
            >
              <div className="breadcrumbs">
                <p>
                  <Link to="/">Integrations</Link>
                </p>
                <img src={arrow} alt="arrow" title="Arrow" />
                <p>
                  <Link to={slug}>
                    <strong>{categoryTitle}</strong>
                  </Link>
                </p>
              </div>
              <Banner
                activeCategory={categoryTitle}
                card={
                  (Array.isArray(mostPopularCard) && mostPopularCard[0]) || formattedIntegration[0]
                }
                text="Connect qualitative and quantitative analytics for even more context."
              />
            </Content>
          </ExtendGridBox>
        </Section>
      )}
    </Layout>
  );
};

export default CategoryPage;

export const query = graphql`
  query ($slug: [String]) {
    allIntegrationsType(integrations: { elemMatch: { integration_id: { in: $slug } } }) {
      integrations {
        integration_id
        description
        how_to_install
        icon
        index
        install_url
        main_category
        short_description
        slug
        tutorial_url
      }
    }
  }
`;
