import React from 'react';
import CustomLink from 'components/ui/link';
import star from 'images/ui/starBanner.svg';
import { data } from 'data/marketplace/sidebar';
import * as Styled from './styled';

interface BannerProps {
  activeCategory: string;
  card: {
    title: string;
    description: string;
    icon: string;
    link: string;
    short_description: string;
  };
  text: string;
}

const Banner: React.FC<BannerProps> = ({ activeCategory, card, text }) => {
  const [getCurrentCat] = data.flatMap(({ list }) =>
    list.filter(({ title }) => title === activeCategory),
  );

  return (
    <Styled.Banner>
      <Styled.MainContent>
        {getCurrentCat.image}
        <h3>
          <span>{activeCategory}</span>
        </h3>
        <p>{text}</p>
      </Styled.MainContent>
      <div>
        <Styled.Card href={card.link}>
          <div className="header-container">
            <img src={card.icon} alt={`Logo - ${card.title}`} title={card.title} className="logo" />
            <div>
              <img src={star} alt="Star" title="Most popular" />
              <p>Most popular</p>
            </div>
          </div>
          <h4>{card.title}</h4>
          <p>{card.short_description}</p>
          <CustomLink withArrow tag="p">
            Learn more
          </CustomLink>
        </Styled.Card>
      </div>
    </Styled.Banner>
  );
};

export default Banner;
